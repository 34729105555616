// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route   } from 'react-router-dom';
import Login from './layout/Login';
import Dashboard from './layout/Dashboard';
import "./sass/main.css";
import Content from './veiws/Hr/Content';
import Empolyee from './veiws/Hr/Employee/Index';
import AllEmployee from './veiws/Hr/Employee/AllEmployee';
import CreatEmployee from './veiws/Hr/Employee/Create';
import UpdateEmployee from './veiws/Hr/Employee/Update';
import Attendance from './veiws/Hr/Attendance/Index';
import AllAttendance from './veiws/Hr/Attendance/AllAttendance';
import CreatAttendance from './veiws/Hr/Attendance/Create';
import UpdateAttendance from './veiws/Hr/Attendance/Update';
import ShowEmployee from './veiws/Hr/Employee/ShowEmployee';
import CreatExelAttendance from './veiws/Hr/Attendance/CreateExel';
import Addetion from './veiws/Hr/Attendance/Addetion';
import Deduction from './veiws/Hr/Attendance/Deduction';
import Payroll from './veiws/Hr/Payroll';
import InactivePayroll from './veiws/Hr/InactivePayroll';
import LeaveBalance from './veiws/Hr/LeaveBalance';
import Warning from './veiws/Hr/Warning';
import EmployeeAttendance from './veiws/public/EmploeeAttendance';
import RFE from './veiws/public/RFE';
import Target from './veiws/public/Target';
import RFEDashbord from './veiws/public/RFEDashbord';
import RequestApprovable from './veiws/Hr/RequestApprovable';
import RequrmentDashbord from './veiws/public/Requrment/RequrmentDashbord';
import AddRequrment from './veiws/public/Requrment/AddRequrment';
import RequrmentApprovable from './veiws/Hr/RequrmentApprovable';
const App = () => {
  useEffect(() => {

    const interval = setInterval(() => {
      // Clear localStorage every 10 hours
      localStorage.clear();
    }, 10 * 60 * 60 * 1000); // 10 hours in milliseconds
    return () => clearInterval(interval);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/" element={<Dashboard/>} >
          <Route index element={<Content/>}/>
          <Route path="employee-attendance" element={<EmployeeAttendance/>} />
          <Route path="/RFE" element={<RFE/>} />
          <Route path="/Target" element={<Target/>} />
          <Route path="/RFEDashbord" element={<RFEDashbord/>} />
          <Route path="/AddRequrment" element={<AddRequrment/>} />
          <Route path="/Requirements" element={<RequrmentDashbord/>} />
          <Route path="employee" element={<Empolyee/>} >
               <Route index element={<AllEmployee/>} />
               <Route path="create" element={<CreatEmployee/>} />
               <Route exact path=":id" element={<ShowEmployee/>} />
               <Route path=":id/edit" element={<UpdateEmployee/>} />

          </Route>
          <Route path="attendance" element={<Attendance/>} >
               <Route index element={<AllAttendance/>} />
               <Route path="create" element={<CreatAttendance/>} />
               <Route path="create/exel" element={<CreatExelAttendance/>} />
               <Route path=":id/edit" element={<UpdateAttendance/>} />
               <Route path=":id/Addetion" element={<Addetion/>} />
               <Route path=":id/Deduction" element={<Deduction/>} />

          </Route>
          <Route path="/Request-Approvable" element={<RequestApprovable/>} />
          <Route path="/Requrment-Approvable" element={<RequrmentApprovable/>} />
          <Route path="/payroll" element={<Payroll/>} />
          <Route path="/inactive-payroll" element={<InactivePayroll/>} />
          <Route path="/leave-balance" element={<LeaveBalance/>} />
          <Route path="/warning" element={<Warning/>} />
          

        </Route>
        
      </Routes>
    </Router>
  );
};

export default App;