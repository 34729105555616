import React, { useEffect, useState } from "react";
import {  ShowAllAttendance } from "../../apis/Attendance";
import moment from 'moment';

export default function EmployeeAttendance(params) {
  const [Employee, setEmployee] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [endsearchQuery, setendSearchQuery] = useState('');
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
        try {
            setSearchQuery(moment().subtract(1, 'days').format('YYYY-MM-DD'));
            setendSearchQuery(moment().subtract(1, 'days').format('YYYY-MM-DD'));
            const userFromApi = await ShowAllAttendance();
            setEmployee(userFromApi.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error if necessary
        }
    };

    fetchData();
}, []);

// Filter employees based on the search query
const filteredEmployees = Employee.filter((item) => {
    return  item.date >= searchQuery && item.date <= endsearchQuery && item.name.includes(searchName);
});
    return         <div class="main-panel">
    <div class="content-wrapper">
    <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Attendance Data</h4>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <div id="order-listing_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row sticky-column">
                                        <div className="col-sm-12 col-md-12">
                                            <div  className="sortable-table-1">
                                                <label>
                                                    From:
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Search"
                                                        aria-controls="order-listing"
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                </label>
                                                <label className="m-2">
                                                    To:
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Search"
                                                        aria-controls="order-listing"
                                                        value={endsearchQuery}
                                                        onChange={(e) => setendSearchQuery(e.target.value)}
                                                    />
                                                </label>
                                                <label className="m-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by Name"
                                                    aria-controls="order-listing"
                                                    value={searchName}
                                                    onChange={(e) => setSearchName(e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table id="order-listing" className="table dataTable no-footer table-container" role="grid" aria-describedby="order-listing_info">
                                                <thead>
                                                    <tr>
                                                        <th className=" ">#</th>
                                                        <th className=" " colSpan={1}></th>
                                                        <th className=" sticky-column" colSpan={1}>Full Name</th>
                                                        <th className=" sticky-column" >Date</th>
                                                        <th className=" ">Employee ID</th>
                                                        <th className=" ">Department</th>
                                                        <th className=" ">Time Stamp</th>
                                                        <th className=" " colSpan={3}>Working Days</th>
                                                        <th className=" "></th>
                                                        <th className=" ">Expected Clock In</th>
                                                        <th className=" ">Clock In</th>
                                                        <th className=" ">Expected Clock Out</th>
                                                        <th className=" ">Clock Out</th>
                                                        <th className=" ">Working Duration</th>
                                                        <th className=" ">Note</th>
                                                        <th className=" ">Addition</th>
                                                        <th className=" ">Deduction</th>
                                                        <th className=" ">Work Day Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredEmployees.length > 0 ? (
                                                        filteredEmployees.map((item, index) => (
                                                            item.department=="Human Resource"?<tr role="row" className="odd" key={index}>
                                                            <td className="sorting_1">{index + 1}</td>
                                                            <td className="sorting_1"><img src={"http://erpsystem.darakoutlet.com" + item.profileimage} alt="profile" /></td>
                                                            <td className="sticky-column">{item.name}</td>
                                                            <td className="sticky-column">{item.date}</td>
                                                            <td>{item.hr_code}</td>
                                                            <td>{item.department}</td>
                                                            <td>{item.TimeStamp}</td>
                                                            <td>{item.startwork }  </td><td className="p-0">to</td>
                                                            <td>{item.endwork}</td>
                                                            <td style={{ textAlign: "center", color: "red" }}>{item.Absent ? <>Absent</> : <></>}</td>
                                                            <td>{item.clockin}</td>
                                                            {item.Absent ? <td></td> : <td style={item.Must_C_In ? { color: "green" } : { color: "red" }}>{item.Clock_In}</td>}
                                                            <td>{item.clockout}</td>
                                                            {item.Absent ? <td></td> : <td style={item.Must_C_Out ? { color: "green" } : { color: "red" }}>{item.Clock_Out}</td>}
                                                            <td>{item.Work_Time}</td>
                                                            <td>{item.note}</td>
                                                            <td style={{ color: "green" }}>{item.addetion} Day</td>
                                                            <td style={{ color: "red" }}>{item.deduction} Day</td>
                                                            <td >{1+item.addetion-item.deduction} Day</td>

                                                        </tr>:<></>
                                                        ))
                                                    ) : (
                                                        <tr><td colSpan={20}>Loading Data</td></tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
   
</div>
}