import { useState,useEffect } from "react";
import {  UserData } from "../Api";
import { Link } from "react-router-dom";

export default function SidBar(params) {
  const [User ,SetUser]=useState(); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonitem = localStorage.getItem('user');
        const jsonString = JSON.parse(jsonitem);
        SetUser(jsonString);

      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error if necessary
      }
    };

    fetchData();
  }, []);
    return<>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      {
        User? <div className="st">
          <ul class="nav sidnav">
        <li class="nav-item">
          <div class="d-flex sidebar-profile">
            <div class="sidebar-profile-image">
              <img src={"https://erpsystem.darakoutlet.com"+User?.profileimage} alt="image"/>
              <span class="sidebar-status-indicator"></span>
            </div>
            <div class="sidebar-profile-name">
              <p class="sidebar-name">
                {User.name}
              </p>
              <p class="sidebar-designation">
                {User.department} {User.job_role}
              </p>
            </div>
          </div>
          
          <p class="sidebar-menu-title">General </p>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/">
            <i class="typcn typcn-device-desktop menu-icon"></i>
            <span class="menu-title">Dashboard </span>
          </Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/employee-attendance">
            <i class="typcn typcn-th-list menu-icon"></i>
            <span class="menu-title">Attendance</span>
          </Link>
        </li>
         <li class="nav-item">
          <Link class="nav-link" to="/RFEDashbord">
            <i class="typcn typcn-document-text menu-icon"></i>
            <span class="menu-title">Requests</span>
          </Link>
        </li>
       
        <li class="nav-item">
          <p class="sidebar-menu-title">Specific </p>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/employee">
            <i class="typcn typcn-group menu-icon"></i>
            <span class="menu-title">Employee Repository</span>
          </Link>
        </li>
         <li class="nav-item">
          <Link class="nav-link" to="/attendance">
            <i class="typcn typcn-th-list menu-icon"></i>
            <span class="menu-title">Timesheet</span>
          </Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/Request-Approvable">
            <i class="typcn typcn-document-text menu-icon"></i>
            <span class="menu-title">Requests Approvals</span>
          </Link>
        </li>
       
         <li class="nav-item">
          <Link class="nav-link" to="/payroll">
            <i class="typcn typcn-th-list menu-icon"></i>
            <span class="menu-title">Payroll</span>
          </Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/inactive-payroll">
            <i class="typcn typcn-gift menu-icon"></i>
            <span class="menu-title">Incentive Remuneration</span>
          </Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/leave-balance">
            <i class="typcn typcn-calendar-outline menu-icon"></i>
            <span class="menu-title">Leave Balance</span>
          </Link>
        </li>
         <li class="nav-item">
          <Link class="nav-link" to="/warning">
            <i class="typcn typcn-warning-outline menu-icon"></i>
            <span class="menu-title">Warning Log</span>
          </Link>
        </li>
        <div className="mt-20"></div>
      </ul>
        </div>:<></>
  
      }
      
      </nav>
    </>
}