import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
export default function Content(params) {
  const [User ,SetUser]=useState(); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonitem = localStorage.getItem('user');
        const jsonString = JSON.parse(jsonitem);
        SetUser(jsonString);

      } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle error if necessary
      }
    };

    fetchData();
  }, []);
  const handlePrint = () => {
    window.print();
  };
    return<div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-sm-6">
          <h3 class="mb-0 font-weight-bold">{User?.name}</h3>
          <p>Your last login: 21h ago from newzealand.</p>
        </div>
        <div class="col-sm-6">
          <div class="d-flex align-items-center justify-content-md-end">
            <div class="mb-3 mb-xl-0 pr-1">
                <div class="dropdown">
                  <button class="btn btn-dark btn-sm dropdown-toggle btn-icon-text border me-2" type="button" id="dropdownMenuSizeButton3" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="typcn typcn-calendar-outline me-2"></i>This Week
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuSizeButton3" data-x-placement="top-start">
                    <h6 class="dropdown-header">This Week</h6>
                    <a class="dropdown-item" href="#">This Month</a>
                    <a class="dropdown-item" href="#">This Quarter</a>
                    <a class="dropdown-item" href="#">This Year</a>
                  </div>
                </div>
            </div>
            <div class="pr-1 mb-3 me-2 mb-xl-0">
              <button type="button" onClick={()=>handlePrint()} class="btn btn-sm btn-dark btn-icon-text border"><i class="typcn typcn-arrow-forward-outline me-2"></i>Export</button>
            </div>

          </div>
        </div>
      </div>
      <div class="row  mt-3">
        <div class="col-xl-5 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Sessions by Channel</h4>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-lg-6">
                      <div id="circleProgress7" class="progressbar-js-circle rounded p-3"><svg viewBox="0 0 100 100" style={styles.progressbarjscircle}><path d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90" stroke="#1f2130" stroke-width="10" fill-opacity="0"></path><path d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90" stroke="rgb(46,32,199)" stroke-width="10" fill-opacity="0" style={{strokeDasharray: "282.783, 282.783", strokeDashoffset:" 70.6958"}}></path></svg><div class="progressbar-text" style={{position: "absolute", left: "50%", top: "50%", padding: "0px", margin: "0px", transform: "translate(-50%, -50%)", color: "rgb(156, 159, 166)", fontSize: "1.875rem", fontWeight: "700"}}><p class="text-center mb-0">Score</p>75%</div></div>
                    </div>
                    <div class="col-lg-6">
                      <ul class="session-by-channel-legend">
                        <li>
                          <div>Firewalls(3)</div>
                          <div>4(100%)</div>
                        </li>
                        <li>
                          <div>Ports(12)</div>
                          <div>12(100%)</div>
                        </li>
                        <li>
                          <div>Servers(233)</div>
                          <div>2(100%)</div>
                        </li>
                        <li>
                          <div>Firewalls(3)</div>
                          <div>7(100%)</div>
                        </li>
                        <li>
                          <div>Firewalls(3)</div>
                          <div>6(70%)</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Events</h4>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                      <div class="d-flex justify-content-between mb-md-5 mt-3">
                        <div class="small">Critical</div>
                        <div class="text-danger small">Error</div>
                        <div class="text-warning small">Warning</div>
                      </div>
                      <canvas id="eventChart" width="176" height="88" style={{display: "block", width: "176px", height: "88px"}} class="chartjs-render-monitor"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Device stats</h4>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="d-flex justify-content-between mb-4">
                        <div>Uptime</div>
                        <div class="text-muted">195 Days, 8 hours</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div>First Seen</div>
                        <div class="text-muted">23 Sep 2019, 2.04PM</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div>Collected time</div>
                        <div class="text-muted">23 Sep 2019, 2.04PM</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div>Memory space</div>
                        <div class="text-muted">168.3GB</div>
                      </div>
                      <div class="progress progress-md mt-4">
                        <div class="progress-bar bg-success" role="progressbar" style={{width: "50%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Sessions by Channel</h4>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="d-flex justify-content-between mb-4">
                        <div class="font-weight-medium">Empolyee Name</div>
                        <div class="font-weight-medium">This Month</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div class="text-secondary font-weight-medium">Connor Chandler</div>
                        <div class="small">$ 4909</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div class="text-secondary font-weight-medium">Russell Floyd</div>
                        <div class="small">$857</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div class="text-secondary font-weight-medium">Douglas White</div>
                        <div class="small">$612	</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div class="text-secondary font-weight-medium">Alta Fletcher </div>
                        <div class="small">$233</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div class="text-secondary font-weight-medium">Marguerite Pearson</div>
                        <div class="small">$233</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div class="text-secondary font-weight-medium">Leonard Gutierrez</div>
                        <div class="small">$35</div>
                      </div>
                      <div class="d-flex justify-content-between mb-4">
                        <div class="text-secondary font-weight-medium">Helen Benson</div>
                        <div class="small">$43</div>
                      </div>
                      <div class="d-flex justify-content-between">
                          <div class="text-secondary font-weight-medium">Helen Benson</div>
                          <div class="small">$43</div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Sales Analytics</h4>
                <button type="button" class="btn btn-sm btn-dark border">MONTH</button>
              </div>
              <div class="row">
                <div class="col-12"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                  <div class="d-md-flex mb-4">
                    <div class="me-md-5 mb-4">
                      <h5 class="mb-1"><i class="typcn typcn-globe-outline me-1"></i>Online</h5>
                      <h2 class="text-primary mb-1 font-weight-bold">23,342</h2>
                    </div>
                    <div class="me-md-5 mb-4">
                      <h5 class="mb-1"><i class="typcn typcn-archive me-1"></i>Offline</h5>
                      <h2 class="text-secondary mb-1 font-weight-bold">13,221</h2>
                    </div>
                    <div class="me-md-5 mb-4">
                      <h5 class="mb-1"><i class="typcn typcn-tags me-1"></i>Marketing</h5>
                      <h2 class="text-warning mb-1 font-weight-bold">1,542</h2>
                    </div>
                  </div>
                  <canvas id="salesanalyticChart" width="442" height="221" style={{display: "block", width: "442px", height: "221px"}} class="chartjs-render-monitor"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Card Title</h4>
              </div>
              <div class="row">
                <div class="col-12"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                  <div class="mb-5">
                    <div class="me-1">
                      <div class="text-info mb-1">
                        Total Earning
                      </div>
                      <h2 class="mb-2 mt-2 font-weight-bold">287,493$</h2>
                      <div class="font-weight-bold">
                        1.4%  Since Last Month
                      </div>
                    </div>
                    <hr/>
                    <div class="me-1">
                      <div class="text-info mb-1">
                        Total Earning
                      </div>
                      <h2 class="mb-2 mt-2  font-weight-bold">87,493</h2>
                      <div class="font-weight-bold">
                        5.43%  Since Last Month
                      </div>
                    </div>
                  </div>
                  <canvas id="barChartStackedDark" width="176" height="88" style={{display: "block", width: "176px", height: "88px"}} class="chartjs-render-monitor"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">E-Commerce Analytics</h4>
              </div>
              <div class="row">
                <div class="col-lg-9">
                  <div class="d-sm-flex justify-content-between">

                      <div class="dropdown">
                        <button class="btn btn-dark border btn-sm dropdown-toggle btn-icon-text" type="button" id="dropdownMenuSizeButton4" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Mon,1 Oct 2019 - Tue,2 Oct 2019
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuSizeButton4" data-x-placement="top-start">
                          <h6 class="dropdown-header">Mon,17 Oct 2019 - Tue,25 Oct 2019</h6>
                          <a class="dropdown-item" href="#">Tue,18 Oct 2019 - Wed,26 Oct 2019</a>
                          <a class="dropdown-item" href="#">Wed,19 Oct 2019 - Thu,26 Oct 2019</a>
                        </div>
                      </div>
                    <div>
                      <button type="button" class="btn btn-sm btn-dark border me-2">DAY</button>
                      <button type="button" class="btn btn-sm btn-dark border me-2">WEEK</button>
                      <button type="button" class="btn btn-sm btn-dark border">MONTH</button>
                    </div>
                  </div>
                  <div class="chart-container mt-4"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
                    <canvas id="ecommerceAnalyticDark" width="723" height="361" style={{display: "block", width:" 723px", height: "361px"}} class="chartjs-render-monitor"></canvas>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="text-success font-weight-bold">Inbound</div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="font-weight-medium">Current</div>
                      <div class="text-muted">38.34M</div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="font-weight-medium">Average</div>
                      <div class="text-muted">38.34M</div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="font-weight-medium">Maximum</div>
                      <div class="text-muted">68.14M</div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="font-weight-medium">60th %</div>
                      <div class="text-muted">168.3GB</div>
                    </div>
                  </div>
                  <hr/>
                  <div class="mt-4">
                    <div class="d-flex justify-content-between mb-3">
                      <div class="text-success font-weight-bold">Outbound</div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="font-weight-medium">Current</div>
                      <div class="text-muted">458.77M</div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="font-weight-medium">Average</div>
                      <div class="text-muted">1.45K</div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="font-weight-medium">Maximum</div>
                      <div class="text-muted">15.50K</div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="font-weight-medium">60th %</div>
                      <div class="text-muted">45.5</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div>
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Sale Analysis Trend</h4>
              </div>
              <div class="mt-2">
                <div class="d-flex justify-content-between">
                  <small>Order Value</small>
                  <small>155.5%</small>
                </div>
                <div class="progress progress-md  mt-2">
                  <div class="progress-bar bg-secondary" role="progressbar" style={{width: "80%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div class="mt-4">
                <div class="d-flex justify-content-between">
                  <small>Total Products</small>
                  <small>238.2%</small>
                </div>
                <div class="progress progress-md  mt-2">
                  <div class="progress-bar bg-success" role="progressbar" style={{width: "50%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div class="mt-4 mb-5">
                <div class="d-flex justify-content-between">
                  <small>Quantity</small>
                  <small>23.30%</small>
                </div>
                <div class="progress progress-md mt-2">
                  <div class="progress-bar bg-warning" role="progressbar" style={{width: "30%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <canvas id="salesTopChart" width="267" height="133" style={{display: "block", width: "267px", height: "133px"}} class="chartjs-render-monitor"></canvas>
            </div>
          </div>
        </div>
        <div class="col-lg-8 d-flex grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-wrap justify-content-between">
                <h4 class="card-title mb-3">Project status</h4>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <img class="img-sm rounded-circle mb-md-0 me-2" src="../../images/faces/face30.png" alt="profile image"/>
                          <div>
                            <div> Company</div>
                            <div class="font-weight-bold mt-1 text-muted">volkswagen</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        Budget
                        <div class="font-weight-bold  mt-1 text-muted">$2322 </div>
                      </td>
                      <td>
                        Status
                        <div class="font-weight-bold text-success  mt-1">88% </div>
                      </td>
                      <td>
                        Deadline
                        <div class="font-weight-bold  mt-1 text-muted">07 Nov 2019</div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-sm btn-dark border">edit actions</button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <img class="img-sm rounded-circle mb-md-0 me-2" src="../../images/faces/face31.png" alt="profile image"/>
                          <div>
                            <div> Company</div>
                            <div class="font-weight-bold  mt-1 text-muted">Land Rover</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        Budget
                        <div class="font-weight-bold mt-1 text-muted">$12022  </div>
                      </td>
                      <td>
                        Status
                        <div class="font-weight-bold text-success  mt-1">70% </div>
                      </td>
                      <td>
                        Deadline
                        <div class="font-weight-bold mt-1 text-muted">08 Nov 2019</div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-sm btn-dark border">edit actions</button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <img class="img-sm rounded-circle mb-md-0 me-2" src="../../images/faces/face32.png" alt="profile image"/>
                          <div>
                            <div> Company</div>
                            <div class="font-weight-bold mt-1 text-muted">Bentley </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        Budget
                        <div class="font-weight-bold mt-1 text-muted">$8,725</div>
                      </td>
                      <td>
                        Status
                        <div class="font-weight-bold text-success  mt-1">87% </div>
                      </td>
                      <td>
                        Deadline
                        <div class="font-weight-bold mt-1 text-muted">11 Jun 2019</div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-sm btn-dark border">edit actions</button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <img class="img-sm rounded-circle mb-md-0 me-2" src="../../images/faces/face33.png" alt="profile image"/>
                          <div>
                            <div> Company</div>
                            <div class="font-weight-bold mt-1 text-muted">Morgan </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        Budget
                        <div class="font-weight-bold mt-1 text-muted">$5,220 </div>
                      </td>
                      <td>
                        Status
                        <div class="font-weight-bold text-success  mt-1">65% </div>
                      </td>
                      <td>
                        Deadline
                        <div class="font-weight-bold  mt-1 text-muted">26 Oct 2019</div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-sm btn-dark border">edit actions</button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <img class="img-sm rounded-circle mb-md-0 me-2" src="../../images/faces/face34.png" alt="profile image"/>
                          <div>
                            <div> Company</div>
                            <div class="font-weight-bold  mt-1 text-muted">volkswagen</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        Budget
                        <div class="font-weight-bold  mt-1 text-muted">$2322 </div>
                      </td>
                      <td>
                        Status
                        <div class="font-weight-bold text-success mt-1">88% </div>
                      </td>
                      <td>
                        Deadline
                        <div class="font-weight-bold  mt-1 text-muted">07 Nov 2019</div>
                      </td>
                      <td>
                        <button type="button" class="btn btn-sm btn-dark border">edit actions</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
</div>
}
const styles = {
    progressbarjscircle: {
        display: 'block', 
        width: '100%',
    },
  };
  