// src/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../Api';

const Login = () => {
  const navigate = useNavigate();
  const [loginerror,Seterror]=useState("");
  const [emailerror,setemailerror]=useState("");
  const [passworderror,setpassorderror]=useState("");
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(credentials);
        if (response.status==400) {
            Seterror("");
            if(response.message.password){
                setpassorderror(response.message.password);
            }
            
            if(response.message.email){
                setemailerror(response.message.email);
            }
        }
        else if(response.status==200){
            localStorage.setItem('token', response.token);
            const jsonString = JSON.stringify(response.user);
            localStorage.setItem('user', jsonString);
            navigate('/');
        }
        else{
            Seterror(response.message);
            setpassorderror("");
            setemailerror("");
        }

    } catch (error) {
        console.log(error.message); // Assuming the error response contains a 'data' field with the error message
    }
  };

  return (

        <div className="container-scroller login">
    <div className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo">
                <img src={require("../assets/logo/Untitled-2-02.png")} alt="logo"/>
              </div>


              <h4 className='h4'>ERP System</h4>
              {/* <h6 className="font-weight-light">Sign in to continue.</h6> */}
             {loginerror!=""? <div className="card card-inverse-warning" id="context-menu-open">
                    <div className="card-body">
                      <p className="card-text">
                      {loginerror}</p>
                    </div>
                  </div>:<></>}
              <form onSubmit={handleSubmit} className="pt-3">
                <div className="form-group ">
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="form-control form-control-lg logininput" id="exampleInputEmail1" 
                    value={credentials.email}
                    onChange={handleChange}
                    required
                    aria-invalid="true"
                    />     
                    {
                        emailerror!=""?<label id="cemail-error" 
                        className="error mt-2 text-danger" htmlFor="cemail">
                           {emailerror}</label>:<></>
                    }
           
                    </div>
                <div className="form-group ">
                <input
          type="password"
          name="password"
          placeholder="Password"
          className="form-control form-control-lg logininput" id="exampleInputPassword1"
          value={credentials.password}
          onChange={handleChange}
          required
          aria-invalid="true"
        />
        {
            passworderror!=""?<label id="cpassword-error"
             className="error mt-2 text-danger"
             htmlFor="cpassword">
                {passworderror}
                </label>:<></>

        }
                </div>
                <div className="mt-3">
                <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit">SIGN IN</button>
                </div>
               
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      
  );
};

export default Login;