import { Icon } from '@iconify/react';
import { useState,useEffect } from "react";
import { useNavigate  } from 'react-router-dom';
import { logout , UserData } from '../Api';
export default function Navbar(params) {
    const navigate = useNavigate();
    const [CurrentTime ,setCurrentTime]=useState(new Date()); 


      useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
            
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, []);
        const day = CurrentTime.getDate();
        const month = CurrentTime.getMonth() + 1; // Months are zero-based
        const year = CurrentTime.getFullYear();
        const hour = CurrentTime.getHours();
        const minute = CurrentTime.getMinutes();
        const second = CurrentTime.getSeconds();
    const handleLogout = async () => {
        try {
          // Make an API request to log out the user
          await logout();
          // Remove the authentication token from local storage or cookie
          localStorage.removeItem('token');
          // Redirect to the login page after logout
          navigate('/login');
        } catch (error) {
          console.error('Logout failed:', error);
        }
      };
    return<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <a class="navbar-brand brand-logo" href="index.html"><img src={require("../assets/logo/Untitled-2-02.png")} alt="logo"/></a>
      <a class="navbar-brand brand-logo-mini" href="index.html"><img src={require("../assets/logo/Untitled-2-02.png")} alt="logo"/></a>
      <button class="navbar-toggler navbar-toggler align-self-center d-none d-lg-flex" type="button" data-toggle="minimize">
        
        <Icon icon="typcn:th-menu" />

      </button>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <div class="navbar-nav mr-lg-2">
        <li class="nav-item  d-none d-lg-flex">
          <a class="nav-link" href="#">
          Date:  {day}/{month}/{year} {hour}:{minute}:{second} 
          </a>
        </li>
        {/* <li class="nav-item  d-none d-lg-flex">
          <a class="nav-link active" href="#">
            Statistic
          </a>
        </li> */}
       
      </div>
      <ul class="navbar-nav navbar-nav-right">
 
        <li class="nav-item dropdown  d-flex">
          <a class="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
            <Icon class="typcn typcn-bell me-0"icon="typcn:bell" />

            <span class="count bg-danger">2</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
            <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-success">
                  <i class="typcn typcn-info-large mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">Application Error</h6>
                <p class="font-weight-light small-text mb-0">
                  Just now
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-warning">
                  <i class="typcn typcn-cog mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">Settings</h6>
                <p class="font-weight-light small-text mb-0">
                  Private message
                </p>
              </div>
            </a>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-info">
                  <i class="typcn typcn-user-outline mx-0"></i>
                </div>
              </div>
              <div class="preview-item-content">
                <h6 class="preview-subject font-weight-normal">New user registration</h6>
                <p class="font-weight-light small-text mb-0">
                  2 days ago
                </p>
              </div>
            </a>
          </div>
        </li>
        <li class="nav-item nav-profile">
        <a class="nav-link" onClick={handleLogout}>
            <i class="typcn typcn-power text-primary"></i>
            Logout
            </a>
          
        </li>
      </ul>
      <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
        <span class="typcn typcn-th-menu"></span>
      </button>
  </div>
</nav>
}
