import { useState,useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import {CreateRequestfe} from "../../../apis/Requrment";

export default function AddRequrment(params) {
  const [formData, setFormData] = useState({request_type:""});
  const [formErrorData, setFormErrorData] = useState({});
  const navigate = useNavigate();
  const [Employee ,SetEmployee]=useState();  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if(!formData?.title||!formData?.description){
      const errors = {};

  
      if (!formData?.title) {
        errors.title = "The Request Type is required";
      }
      
      if (!formData?.description) {
        errors.description = "The description is required";
      }
  
      // Update the error state with the new errors
      setFormErrorData(errors);            
  }
  else{
      setFormErrorData([]);
      const jsonitem = localStorage.getItem('user');
        const jsonString = JSON.parse(jsonitem);
      formData["asignby"]=jsonString["id"];
      CreateRequestfe(formData).then((res)=>{
        
        if(res.status<202){
          navigate('/Requirements');
        }
      }).catch(function (error) {
        // handle error
        console.log(error);
      });
     
    }
    };
    return <div class="main-panel">
    <div class="content-wrapper">
     <div className="card">
        <div className="card-body">
            <h4 className="card-title">Requirement Request Form</h4>
            <Form onSubmit={handleSubmit} >
              <div className='row'>
              
              <div className='col-lg-12 col-sm-12'>
                      <Form.Label>Role Requested</Form.Label>
                      <Form.Control
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      />
                      {formErrorData.title?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.title}</label>:<></>}

                      </div>
                                          <div className='col-lg-12 col-sm-12'>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                      as="textarea"
                      name="description"
                      style={{height:"150px"}}
                      value={formData.description}
                      onChange={handleInputChange}
                      />
                      {formErrorData.description?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.description}</label>:<></>}

                      </div>
              </div>

    
        

        <div className="d-flex justify-content-between mt-5">

        <Button variant="primary" onClick={handleSubmit}>
        Submit
        </Button>
       
      </div>
    </Form>
                </div>
            </div>
      </div>
   
</div>
}