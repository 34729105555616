import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import { DeleteAttendance, ShowAllAttendance } from "../../../apis/Attendance";
import moment from 'moment';
export default function AllAttendance(params) {
    const [Employee, setEmployee] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [searchName, setSearchName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [endsearchQuery, setendSearchQuery] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setSelectedEmployee(item);
        setShow(true);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setSearchQuery(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                setendSearchQuery(moment().subtract(1, 'days').format('YYYY-MM-DD'));

                const userFromApi = await ShowAllAttendance();
                setEmployee(userFromApi.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                // Handle error if necessary
            }
        };

        fetchData();
    }, []);

    // Filter employees based on the search query
    const filteredEmployees = Employee.filter((item) => {
        return  item.date >= searchQuery && item.date <= endsearchQuery && item.name.includes(searchName);
    });

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Attendance Data</h4>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <div id="order-listing_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row sticky-column">
                                        <div className="col-sm-12 col-md-8">
                                            <div id="order-listing_filter" className="sortable-table-1">
                                            <label>
                                                    From:
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Search"
                                                        aria-controls="order-listing"
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                </label>
                                                <label className="m-2">
                                                    To:
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder="Search"
                                                        aria-controls="order-listing"
                                                        value={endsearchQuery}
                                                        onChange={(e) => setendSearchQuery(e.target.value)}
                                                    />
                                                </label>
                                                <label className="m-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by Name"
                                                    aria-controls="order-listing"
                                                    value={searchName}
                                                    onChange={(e) => setSearchName(e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4 alignend">
                                          
                                            <Link type="button" to={"/attendance/create/exel"} className="btn btn-light btn-icon-text m-1">
                                                Upload Attendance
                                                <i className="typcn typcn-user-add btn-icon-append"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table id="order-listing" className="table dataTable no-footer table-container" role="grid" aria-describedby="order-listing_info">
                                                <thead>
                                                    <tr>
                                                        <th className=" ">#</th>
                                                        <th className=" " colSpan={1}></th>
                                                        <th className=" sticky-column" colSpan={1}>Full Name</th>
                                                        <th className=" sticky-column" >Date</th>
                                                        <th className=" ">Employee ID</th>
                                                        <th className=" ">department</th>
                                                        <th className=" ">Time Stamp</th>
                                                        <th className=" " colSpan={2}>Working Days</th>
                                                        <th className=" "></th>
                                                        {/* <th className=" ">Exeption</th> */}
                                                        <th className=" ">Must Clock In</th>
                                                        <th className=" ">Actual Clock In</th>
                                                        <th className=" ">Must Clock Out</th>
                                                        <th className=" ">Actual Clock Out</th>
                                                        <th className=" ">Working Duration</th>
                                                        <th className=" ">note</th>
                                                        <th className=" ">Additions</th>
                                                        <th className=" ">Deductions</th>
                                                        <th className=" ">Work Day Value</th>
                                                        <th className=" ">Status</th>
                                                        {/* <th className=" center" colSpan={2}>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredEmployees.length > 0 ? (
                                                        filteredEmployees.map((item, index) => (
                                                            <tr role="row" className="odd" key={index}>
                                                                <td className="sorting_1">{index + 1}</td>
                                                                <td className="sorting_1"><img src={"http://erpsystem.darakoutlet.com" + item.profileimage} alt="profile" /></td>
                                                                <td className="sticky-column">{item.name}</td>
                                                                <td className="sticky-column">{item.date}</td>
                                                                <td>{item.hr_code}</td>
                                                                <td>{item.department}</td>
                                                                <td>{item.TimeStamp}</td>
                                                                <td>{item.startwork}</td>
                                                                <td>{item.endwork}</td>
                                                                <td style={{ textAlign: "center", color: "red" }}>{item.Absent ? <>Absent</> : <></>}</td>
                                                                {/* <td>{item.Exception}</td> */}
                                                                <td>{item.clockin}</td>
                                                                {item.Absent ? <td></td> : <td style={item.Must_C_In ? { color: "green" } : { color: "red" }}>{item.Clock_In}</td>}
                                                                <td>{item.clockout}</td>
                                                                {item.Absent ? <td></td> : <td style={item.Must_C_Out ? { color: "green" } : { color: "red" }}>{item.Clock_Out}</td>}
                                                                <td>{item.Work_Time}</td>
                                                                {
                                                                    item.note?<td>{item.note}</td>:<td></td>
                                                                }
                                                                <td style={{ color: "green" }}>{item.addetion} Day</td>
                                                                <td style={{ color: "red" }}>{item.deduction} Day</td>
                                                                <td >{1+item.addetion-item.deduction} Day</td>
                                                                
                                                                <td>
                                                                    {item.isemploee==1 ? <label className="badge badge-success">Active</label> : <label className="badge badge-danger">NOT Active</label>}
                                                                </td>
                                                                

                                                                <td>
                                                                    <Link type="button" to={"/attendance/" + item.id + "/addetion/"} className="btn btn-outline-light btn-icon-text">
                                                                        Take Action
                                                                        <i className="typcn typcn-eye-outline btn-icon-append"></i>
                                                                    </Link>
                                                                </td>
                                                                {/* <td>
                                                                    <Link type="button" to={"/attendance/" + item.id + "/deduction/"} className="btn btn-outline-light btn-icon-text">
                                                                        Deductions
                                                                        <i className="typcn typcn-eye-outline btn-icon-append"></i>
                                                                    </Link>
                                                                </td> */}
                                                                <td>
                                                                    <Link type="button" to={"/attendance/" + item.id + "/edit"} className="btn btn-outline-secondary btn-icon-text">
                                                                        Edit
                                                                        <i className="typcn typcn-edit btn-icon-append"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr><td colSpan={20}>No Data</td></tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        DeleteAttendance(selectedEmployee.id);
                        const updatedEmployees = Employee.data.filter(employee => employee.id !== selectedEmployee.id);
                        setEmployee(prevState => ({ ...prevState, data: updatedEmployees }));                        handleClose();
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
