import { useEffect, useState } from "react";
import { DeleteEmployee, ShowAllEmployee } from "../../../apis/Employee";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Form, Modal } from "react-bootstrap";
export default function AllEmployee(params) {
    const [Employee ,SetEmployee]=useState();  
     const [show, setShow] = useState(false);
     const [selectedemployee, setselectedemployee] = useState();
     const [formData, setFormData] = useState({});
     const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query
     const [formErrorData, setFormErrorData] = useState({});
     const [departmentFilter, setDepartmentFilter] = useState("");
     const [sortBy, setSortBy] = useState("");
   
     const handleInputChange = (event) => {
       const { name, value } = event.target;
       setFormData({ ...formData, [name]: value });
     };
   
     const handleClose = () => setShow(false);
     const handleShow = (item) => {
       setselectedemployee(item);
       setShow(true);
     };
   
     const filteredEmployees = () => {
       let filteredData = Employee?.data;
   
       if (searchQuery) {
         filteredData = filteredData.filter((item) =>
           item.name.toLowerCase().includes(searchQuery.toLowerCase())
         );
       }
   
       if (departmentFilter) {
         filteredData = filteredData.filter(
           (item) => item.department === departmentFilter
         );
       }
   
       if (sortBy === "Salary up") {
         filteredData = filteredData.sort((a, b) => a.salary - b.salary);
       } else if (sortBy === "Salary down") {
         filteredData = filteredData.sort((a, b) => b.salary - a.salary);
       }
   
       return filteredData;
     };
   
    useEffect(() => {
        const fetchData = async () => {
          try {
            const userFromApi = await ShowAllEmployee();
            SetEmployee(userFromApi);
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Handle error if necessary
          }
        };
    
        fetchData();
      }, []);
      
    return <>
       
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Employee Database</h4>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <div id="order-listing_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row">
                                    <div className="col-sm-12 col-md-8">
                                            <div id="order-listing_filter" className="sortable-table-1">
                                                <label className="ml-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by name"
                                                    aria-controls="order-listing"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />      
                                                      </label>
                                                      <label className="ml-5">
                                                      <select
                                                         className="form-control select"
                                                         aria-controls="order-listing"
                                                         value={departmentFilter}
                                                         onChange={(e) => setDepartmentFilter(e.target.value)}
                                                        >
                                                          <option value="">All Departments</option>
                                                          <option value="Technical Office">The Technical Office</option>
                                                          <option value="Financial Department">Financial Department</option>
                                                          <option value="Operation">Operation</option>
                                                          <option value="Warehouse">Warehouse</option>
                                                          <option value="Administration">Administration</option>
                                                          <option value="Human Resource">Human Resource</option>
                                                          <option value="IT">IT</option>
                                                          <option value="Buffet">Buffet</option>
                                                          <option value="Head Office">Head Office</option>
                                                          <option value="Software">Software</option>
                                                          <option value="Store">Store</option>
                                                          <option value="Control Office">Control Office</option>
                                                          <option value="Supply Chain">Supply Chain</option>
                                                          <option value="Sales">Sales</option>
                                                        </select>
                                                        
                                                      </label>
                                                      <label className="ml-5">
                                                      <select
                                                          className="form-control select"
                                                          aria-controls="order-listing"
                                                          value={sortBy}
                                                          onChange={(e) => setSortBy(e.target.value)}
                                                        >
                                                          <option value="">Sort By</option>
                                                          <option value="Salary up">Salary up</option>
                                                          <option value="Salary down">Salary down</option>
                                                          <option value="Employment Duration">Employment Duration</option>
                                                          <option value="Date of Birth">Date of Birth</option>
                                                        </select>
                                                      </label>
                                                    </div>
                                                    </div>
                                        <div className="col-sm-12 col-md-4 alignend">
                                        <Link type="button" to={"/employee/create"} className="btn btn-light btn-icon-text ">
                                        Onboard
                                                            <i className="typcn typcn-user-add btn-icon-append"></i>                          
                                                            </Link>
                                        </div>    
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table id="order-listing" className="table dataTable no-footer table-container" role="grid" aria-describedby="order-listing_info">
                                                <thead>
                                                  <tr>
                                                        <th  className=" sortStyle">#</th>
                                                        <th className="sortStyle "colSpan={1}></th>
                                                        <th  className=" sticky-column" colSpan={2}>Full Name</th>
                                                        <th  className="sortStyle ">Employee ID</th>
                                                        <th className="sortStyle ">Department</th>
                                                        <th className="sortStyle ">Job Role</th>
                                                        <th className="sortStyle ">status</th>
                                                        <th className="sortStyle center" colSpan={3}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {

                                                        Employee?  Employee.status == 200 ? filteredEmployees().map( (item,index) =>{
                                                        return item.isemploee==1?<tr role="row" className="odd" key={index}>
                                                        <td className="sorting_1">{index+1}</td>
                                                        <td className="sorting_1"><img src={"https://erpsystem.darakoutlet.com"+item.profileimage}/></td>
                                                        <td colSpan={2} className="sticky-column">{item.name}</td>
                                                        <td>{item.hr_code}</td>
                                                        <td>{item.department}</td>
                                                        <td>{item.job_role}</td>
                                                        <td>
                                                            {item.isemploee==1?<label className="badge badge-success">Active</label>:<label className="badge badge-danger">Not Active</label>}
                                                            
                                                        </td>
                                                        <td>
                                                        <Link type="button" to={"/employee/"+item.id} className="btn btn-outline-light btn-icon-text">
                                                            Preview 
                                                            <i className="typcn typcn-eye-outline btn-icon-append"></i>                          
                                                            </Link>
                                                        </td>
                                                        <td>
                                                        <Link type="button" to={"/employee/"+item.id+"/edit"} className="btn btn-outline-secondary btn-icon-text">
                                                            Edit
                                                            <i className="typcn typcn-edit btn-icon-append"></i>                          
                                                            </Link>
                                                        </td>
                                                        <td>
                                                        <button type="button" className="btn btn-outline-danger btn-icon-text" onClick={()=>handleShow(item)}>
                                                        Edit Status 
                                                            <i className="typcn typcn-edit btn-icon-append"></i>                          
                                                            </button>
                                                        </td>
                                                    </tr>:<></>
                                                    }): <><tr><td style={{ textAlign: "center" }} colSpan={12}>{Employee.data}</td></tr></> : <tr><td style={{ textAlign: "center" }} colSpan={12}>Loading data ...</td></tr>
                                                        
                                                    }
                                                    </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {selectedemployee?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>are you sure ?

        <div className="row">
      <div className='col-12 mt-5'>
        <Form.Label>Reason</Form.Label>
        <Form.Control as="select"  name="Reason" className='select' value={formData.Reason} onChange={handleInputChange}>
        <option >choose ....</option>
        <option value="Long Leave">Long Leave</option>
          <option value="Termination">Termination</option>
          <option value="Resignation">Resignation</option>
        {/* Add more options as needed */}
        </Form.Control>
        {formErrorData.Reason?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.Reason}</label>:<></>}
         
        </div>
      </div>
        </Modal.Body>
    
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-danger" onClick={()=>{
            const errors = {};
            if (!formData?.Reason) {
              errors.Reason = "The Reason is required";
              setFormErrorData(errors); 
            }
             else{
              DeleteEmployee(selectedemployee?.id,formData).then(res=>{

                console.log(res);
              });
              const updatedEmployees = Employee.data.filter(employee => employee.id !== selectedemployee.id);
              SetEmployee(prevState => ({ ...prevState, data: updatedEmployees }));
              handleClose();
             }

          }}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
}