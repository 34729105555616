import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {  CreateEmployee, ShowAllPayroll } from "../../apis/Payroll";
import { Form, Modal } from "react-bootstrap";

export default function Payroll(params) {
  const [Employee, SetEmployee] = useState();
  const [show, setShow] = useState(false);
  const [selectedemployee, setselectedemployee] = useState();
  const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query
  const [month, setMonth] = useState('');
  const [today, settoday] = useState(new Date());
  const [year, setYear] = useState('');
  const [formErrorData, setFormErrorData] = useState({});
  const [formData, setFormData] = useState({});
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [nameFilter, SetnameFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }
  const fetchData = async () => {

    try {
      const userFromApi = await ShowAllPayroll();
      SetEmployee(userFromApi);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle error if necessary
    }
  };
  useEffect(() => {
    const monthValue = (today.getMonth() + 1).toString().padStart(2, '0');
    const yearValue = today.getFullYear();
    setMonth(monthValue);
    setYear(yearValue);
    setSearchQuery(yearValue + "-" + monthValue);


    fetchData();
  }, []);

  
  // Filter employees based on the search query
  const filteredEmployees = Employee?.data?.filter((item) =>
    Object.values(item).some((value) =>
      typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const filteredpay = (item) => {
    let filteredData = item.data;

    if (nameFilter) {
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (departmentFilter) {
      filteredData = filteredData.filter(
        (item) => item.department === departmentFilter
      );
    }

    if (sortBy === "Presence Margin up") {
      filteredData = filteredData.sort((a, b) => a.salary - b.salary);
    } else if (sortBy === "Presence Margin down") {
      filteredData = filteredData.sort((a, b) => b.salary - a.salary);
    }

    return filteredData;
  };
  return (
    <>
        <div className="main-panel">
      <div className="content-wrapper">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Payroll </h4>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <div id="order-listing_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-4">
                        <div id="order-listing_filter" className="sortable-table-1">
                          <label>
                            <input
                              type="month"
                              className="form-control"
                              placeholder="Search"
                              min={"2024-01"}
                              // max={year+"-"+month}
                              aria-controls="order-listing"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-8 alignend">
                        <button type="button" className="btn btn-outline-warning btn-icon-text" onClick={()=>handleShow()}>
                        Submit Monthly Payroll
                            <i className="typcn typcn-edit btn-icon-append"></i>
                          </button>
                      </div>
                    </div>
                    {filteredEmployees?.length > 0 ? (
                      filteredEmployees.map((item, index) => (
                        <>
                      <div class="row">
                      <div class="col-lg-8 pl-0 mt-8 mb-2 d-flex justify-content-around">
                        <label >
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Search by name"
                                                  
                                                  // max={year+"-"+month}
                                                  aria-controls="order-listing"
                                                  
                                                  value={nameFilter}
                                                  onChange={(e) => SetnameFilter(e.target.value)} // Update search query state
                                                />      
                                                      </label>
                                                      <label className="ml-5">
                                                      <select
                                                         className="form-control select" 
                                                         aria-controls="order-listing"
                                                         value={departmentFilter}
                                                         onChange={(e) => setDepartmentFilter(e.target.value)}
                                                        >
                                                          <option value="">All Departments</option>
                                                          <option value="Technical Office">The Technical Office</option>
                                                          <option value="Financial Department">Financial Department</option>
                                                          <option value="Operation">Operation</option>
                                                          <option value="Warehouse">Warehouse</option>
                                                          <option value="Administration">Administration</option>
                                                          <option value="Human Resource">Human Resource</option>
                                                          <option value="IT">IT</option>
                                                          <option value="Buffet">Buffet</option>
                                                          <option value="Head Office">Head Office</option>
                                                          <option value="Software">Software</option>
                                                          <option value="Store">Store</option>
                                                          <option value="Control Office">Control Office</option>
                                                          <option value="Supply Chain">Supply Chain</option>
                                                          <option value="Sales">Sales</option>
                                                        </select>
                                                        
                                                      </label>
                                                      <label className="ml-5">
                                                      <select
                                                         className="form-control select" 
                                                         aria-controls="order-listing"
                                                        >
                                                          <option value="">Sort By</option>
                                                         
                                                          <option value="Presence Margin up">Presence Margin up </option>
                                                          <option value="Presence Margin down">Presence Margin down </option>

                                                        </select>
                                                      </label>
                        </div>
                        <div class="col-lg-6 pl-0 mt-5 mb-2 d-flex justify-content-around">
                          {/* <Link type="button" to={"/Report-submition/" + item.id + "/edit"} className="btn btn-outline-secondary btn-icon-text ">
                            Edit
                            <i className="typcn typcn-edit btn-icon-append"></i>
                          </Link> */}

                        </div>
                      </div>
                      <div class="container-fluid  d-flex justify-content-center w-100">
                        <div class=" w-100">
                          <table class="table">
                            <thead>
                              <tr class="">
                                <th>#</th>
                                <th className="sticky-column">Name</th>
                                <th >Employee ID</th>
                                <th>Department</th>
                                <th >Salary</th>
                                <th>Possible Working Days</th>
                                <th >Holidays</th>
                                <th>Actual Working Days</th>
                                <th>Additions</th>
                                <th >Deductions</th>
                                <th>Daily Rate</th>
                                <th >paid Days</th>
                                <th>Medical Insurance</th>
                                <th >Social Insurance</th>
                                <th>tax</th>
                                <th >Gross Pay</th>
                                <th>Total Liquid Pay</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              {
                                filteredpay(item).map((temp,i)=>{
                                  return<tr class="text-end">
                                  <td class="text-left">{i+1}</td>
                                  <td class="text-left" className="sticky-column">{temp.name}</td>
                                  <td class="text-left">{temp.hr_code}</td>
                                  <td class="text-left">{temp.department}</td>
                                  <td class="text-left">{temp.salary}</td>
                                  <td class="text-left">{temp.workdays}</td>
                                  <td class="text-left">{temp.holidays}</td>
                                  <td class="text-left">{temp.attendance}</td>
                                  <td class="text-left">{temp.additions}</td>
                                  <td class="text-left">{temp.deductions}</td>
                                  <td class="text-left">{temp.dailyrate}</td>
                                  <td class="text-left">{temp.paiddays}</td>
                                  <td class="text-left">{temp.MedicalInsurance}</td>
                                  <td class="text-left">{temp.SocialInsurance}</td>
                                  <td class="text-left">{temp.tax}</td>
                                  <td class="text-left">{temp.TotalPay}</td>
                                  <td class="text-left">{temp.TotalLiquidPay}</td>
                                </tr>
                                })
                              }

                            </tbody>
                          </table>
                        </div>
                      </div>
                      </>
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={12}>No Data.</td>
                      </tr>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal show={show} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title>Incress Month Warning {selectedemployee?.name}</Modal.Title>
</Modal.Header>
<Modal.Body> <div className="row">
      <div className='col-12 mt-5'>
        <Form.Label>Month date</Form.Label>
         <Form.Control
          type="month"
          name="Date"
          min={"2024-01"}
          
          max={year+"-"+((today.getMonth()+1).toString().padStart(2, '0'))}
          onChange={handleInputChange}
        />
        {formErrorData.Date?<label id="cname-error" class="error mt-2 text-danger" for="cname">{formErrorData?.Date}</label>:<></>}
         
        </div>
      </div></Modal.Body>
<Modal.Footer>
  <Button variant="light" onClick={handleClose}>
    Close
  </Button>
  <Button variant="outline-warning" onClick={()=>{
    // IncressEmployeeWarning(selectedemployee?.id);
    const errors = {};
    if (!formData?.Date) {
      errors.Date = "The Month is required";
      setFormErrorData(errors); 
    }
     else{
      // CreateEmployee(formData);
      console.log(formData.Date);
     fetchData();
     handleClose();
     }

  }}>
    Generate
  </Button>
</Modal.Footer>
</Modal>
    </>
    
  );
}